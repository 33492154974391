import React from 'react';
import { Grid, Fade, Button, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuildList(props) {
  const { guilds } = props;

  if (guilds) {
    return (
      <Grid container justifyContent="center" spacing={1}>
        {guilds.map((guild) => (
          <Grid key={guild.id} item xs={12} sm={6} md={4}>
            <Fade in>
              <Button
                component={Link}
                to="/discord/guild"
                sx={{ width: '100%', fontSize: '200%' }}
                state={{
                  guild: { id: guild.id, name: guild.name },
                }}
                variant="contained"
                id={guild.id}
              >
                {guild.name}
              </Button>
            </Fade>
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <Grid container alignContent="center" justifyContent="center" spacing={2}>
      <Grid item xs={6}>
        <Fade in>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Fade>
      </Grid>
      <Grid item xs={6}>
        <Fade in>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Fade>
      </Grid>
      <Grid item xs={6}>
        <Fade in>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Fade>
      </Grid>
      <Grid item xs={6}>
        <Fade in>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Fade>
      </Grid>
    </Grid>
  );
}

GuildList.propTypes = {
  guilds: PropTypes.arrayOf(PropTypes.object),
};

GuildList.defaultProps = {
  guilds: null,
};

export default GuildList;
