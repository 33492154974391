import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from 'firebase/firestore';

function ControlModule(props) {
  const { title, to } = props;
  const [loaded, setLoaded] = useState(false);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    const controlUnsub = onSnapshot(
      doc(getFirestore(), 'control', 'running'),
      (d) => {
        setLoaded(true);
        if (Object.keys(d.data()).includes(to.name)) {
          setRunning(true);
        } else {
          setRunning(false);
        }
      }
    );

    return () => {
      controlUnsub();
    };
  }, []);

  function updateControl(type, toAdd) {
    const updates = {};
    updates[type] = arrayUnion(toAdd);
    updateDoc(doc(getFirestore(), 'control', type), toAdd);
  }

  const sendData = {};
  sendData[to.name] = {
    args: to.args,
    dir: to.dir,
  };

  function start() {
    updateControl('to_start', sendData);
  }

  function stop() {
    const stopData = {};
    stopData[to.name] = null;
    updateControl('to_stop', stopData);
  }

  function restart() {
    updateControl('to_restart', sendData);
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12}>
        {title && (
          <Typography variant="h2" align="center">
            {title}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button
          style={{ width: '100%' }}
          onClick={() => start()}
          variant="contained"
          disabled={loaded ? running : true}
        >
          Start
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button
          style={{ width: '100%' }}
          onClick={() => stop()}
          variant="contained"
          disabled={loaded ? !running : true}
        >
          Stop
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Button
          style={{ width: '100%' }}
          onClick={() => restart()}
          variant="contained"
          disabled={loaded ? !running : true}
        >
          Restart
        </Button>
      </Grid>
    </Grid>
  );
}

ControlModule.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ControlModule;
