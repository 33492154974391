import { initializeApp } from 'firebase/app';
// import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: 'AIzaSyB8Zd7-cQf6pIJEHcLkZUIBipNFjZH6DEc',
  authDomain: 'website-3029e.firebaseapp.com',
  databaseURL: 'https://website-3029e-default-rtdb.firebaseio.com',
  projectId: 'website-3029e',
  storageBucket: 'website-3029e.appspot.com',
  messagingSenderId: '688648015662',
  appId: '1:688648015662:web:340d8a54482c20db9234bf',
};

initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);
