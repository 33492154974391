import '../App.css';
import React from 'react';
import { Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';

function Main() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <Container>
        <br />
        <Typography>6/24/22</Typography>
      </Container>
    </motion.div>
  );
}

export default Main;
