import '../../App.css';
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  IconButton,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Divider,
  Hidden,
  Stack,
  Slider,
  useTheme,
  Skeleton,
} from '@mui/material';
import {
  VolumeDownRounded,
  VolumeUpRounded,
  StopRounded,
  FastForwardRounded,
  PlayArrowRounded,
  PauseRounded,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function Guild(props) {
  const { location } = props;

  const { guild } = location.state;
  const wsRef = useRef();
  const [channel, setChannel] = useState('');
  const [channels, setChannels] = useState(null);

  const [hasMedia, setHasMedia] = useState(false);
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [album, setAlbum] = useState('');
  const [channelName, setChannelName] = useState('');
  const [duration, setDuration] = useState(100);
  const [timeStamp, setTimeStamp] = useState(0);
  const [volume, setVolume] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [coverURL, setCoverURL] = useState(null);

  useEffect(() => {
    wsRef.current = new WebSocket('wss://socket.samjandris.me/discord');
    wsRef.current.onopen = () => {
      wsRef.current.send(
        JSON.stringify({
          event: 'guild',
          data: { guildId: guild.id, query: 'channels' },
        })
      );
      wsRef.current.send(
        JSON.stringify({
          event: 'guild',
          data: { guildId: guild.id, query: 'info' },
        })
      );
    };
    wsRef.current.onmessage = (evt) => {
      // console.log(evt.data);
      const dMsg = JSON.parse(evt.data);
      if (dMsg.event === 'guild channels') {
        const newSet = [];
        dMsg.data.forEach((item) => {
          newSet.push({ id: item.id, name: item.name });
        });
        setChannels(newSet);
      } else if (dMsg.event === 'guild info') {
        // console.log(dMsg.data);
      } else if (dMsg.event === 'music volume') {
        setVolume(dMsg.data * 100);
      } else if (dMsg.event === 'music') {
        if (dMsg.query === 'np') {
          const { data } = dMsg;
          setCoverURL(data.coverArt);
          setChannelName(data.channel);
          setTitle(data.title);
          setArtist(data.artist);
          setAlbum(data.album);
          setPlaying(data.playing);
          setDuration(data.duration);
          setTimeStamp(data.elapsed);
          setVolume(data.volume * 100);
          setHasMedia(data.hasMedia);
        } else if (dMsg.query === 'elapsed') {
          setTimeStamp(dMsg.data.time);
        }
      }
    };
    return () => {
      wsRef.current.close(1000);
    };
  }, []);

  const handleChannelChange = (event) => {
    setChannel(event.target.value);
  };

  const handleVolumeChange = (event) => {
    wsRef.current.send(
      JSON.stringify({
        event: 'music',
        query: 'setVolume',
        data: {
          guildId: guild.id,
          volume: event.target.value,
        },
      })
    );
    setVolume(event.target.value);
  };

  function playMusic() {
    const toSend = {
      event: 'play',
      data: {
        guildId: guild.id,
        channelId: channel,
        toSearch: document.getElementById('toSearch').value,
      },
    };
    wsRef.current.send(JSON.stringify(toSend));
  }

  function playPause() {
    setPlaying(!playing);
    const toSend = {
      event: 'music',
      query: playing ? 'pause' : 'unpause',
      data: { guildId: guild.id },
    };
    wsRef.current.send(JSON.stringify(toSend));
  }

  function next() {
    const toSend = {
      event: 'music',
      query: 'next',
      data: { guildId: guild.id },
    };
    wsRef.current.send(JSON.stringify(toSend));
  }

  function stop() {
    const toSend = {
      event: 'music',
      query: 'stop',
      data: { guildId: guild.id },
    };
    wsRef.current.send(JSON.stringify(toSend));
  }

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft <= 9 ? `0${secondLeft}` : secondLeft}`;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" align="center" sx={{ mt: '20px', mb: '20px' }}>
          {guild.name}
        </Typography>
        <Grid
          container
          sx={{ width: '100%', pl: '2%' }}
          spacing={1}
          justifyContent="center"
        >
          <Grid item xs={11.5} sm={5.75}>
            {/* <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4" align="center">
                  Control
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button sx={{ width: '100%' }} disabled variant="contained">
                  Play
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button sx={{ width: '100%' }} disabled variant="contained">
                  Pause
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Button sx={{ width: '100%' }} disabled variant="contained">
                  Stop
                </Button>
              </Grid>
            </Grid> */}

            <Box
              sx={{
                padding: 2,
                borderRadius: 2,
                // width: 343,
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                position: 'relative',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                {hasMedia ? (
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      objectFit: 'cover',
                      overflow: 'hidden',
                      flexShrink: 0,
                      borderRadius: 2,
                      backgroundColor: 'rgba(0,0,0,0.08)',
                      '& > img': {
                        width: '100%',
                      },
                    }}
                  >
                    <img alt="album artwork" src={coverURL} />
                  </Box>
                ) : (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={100}
                    height={100}
                  />
                )}
                <Box sx={{ ml: 1.5, minWidth: 0 }}>
                  {hasMedia && (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      fontWeight={500}
                    >
                      {channelName}
                    </Typography>
                  )}
                  <Typography noWrap sx={{ width: '500' }}>
                    {hasMedia ? <b>{title}</b> : <b>Not Playing</b>}
                  </Typography>
                  {hasMedia && (
                    <Typography noWrap letterSpacing={-0.25}>
                      {artist} &mdash; {album}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Slider
                aria-label="time-indicator"
                size="small"
                value={timeStamp}
                min={0}
                step={1}
                max={duration}
                onChange={(_, value) => setTimeStamp(value)}
                sx={{
                  color:
                    useTheme().palette.mode === 'dark'
                      ? '#fff'
                      : 'rgba(0,0,0,0.87)',
                  height: 4,
                  '& .MuiSlider-thumb': {
                    width: 8,
                    height: 8,
                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                    '&:before': {
                      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: `0px 0px 0px 8px ${
                        useTheme().palette.mode === 'dark'
                          ? 'rgb(255 255 255 / 16%)'
                          : 'rgb(0 0 0 / 16%)'
                      }`,
                    },
                    '&.Mui-active': {
                      width: 20,
                      height: 20,
                    },
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.28,
                  },
                }}
              />
              {hasMedia && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: -2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      opacity: 0.38,
                      fontWeight: 500,
                      letterSpacing: 0.2,
                    }}
                  >
                    {formatDuration(timeStamp)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      opacity: 0.38,
                      fontWeight: 500,
                      letterSpacing: 0.2,
                    }}
                  >
                    -{formatDuration(duration - timeStamp)}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: -1,
                }}
              >
                <IconButton aria-label="stop playing" onClick={() => stop()}>
                  <StopRounded
                    fontSize="large"
                    sx={{
                      color:
                        useTheme().palette.mode === 'dark' ? '#fff' : '#000',
                    }}
                  />
                </IconButton>
                <IconButton
                  aria-label={playing ? 'pause' : 'play'}
                  onClick={() => playPause()}
                  disabled={!hasMedia}
                >
                  {playing ? (
                    <PauseRounded
                      sx={{
                        fontSize: '3rem',
                        color:
                          useTheme().palette.mode === 'dark' ? '#fff' : '#000',
                      }}
                    />
                  ) : (
                    <PlayArrowRounded
                      sx={{
                        fontSize: '3rem',
                        color:
                          useTheme().palette.mode === 'dark' ? '#fff' : '#000',
                      }}
                    />
                  )}
                </IconButton>
                <IconButton
                  aria-label="next song"
                  onClick={() => next()}
                  disabled={!hasMedia}
                >
                  <FastForwardRounded
                    fontSize="large"
                    sx={{
                      color:
                        useTheme().palette.mode === 'dark' ? '#fff' : '#000',
                    }}
                  />
                </IconButton>
              </Box>
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1, px: 1 }}
                alignItems="center"
              >
                <VolumeDownRounded
                  sx={{
                    color:
                      useTheme().palette.mode === 'dark'
                        ? 'rgba(255,255,255,0.4)'
                        : 'rgba(0,0,0,0.4)',
                  }}
                />
                <Slider
                  aria-label="Volume"
                  value={volume}
                  onChange={handleVolumeChange}
                  disabled={volume === null}
                  sx={{
                    color:
                      useTheme().palette.mode === 'dark'
                        ? '#fff'
                        : 'rgba(0,0,0,0.87)',
                    '& .MuiSlider-track': {
                      border: 'none',
                    },
                    '& .MuiSlider-thumb': {
                      width: 24,
                      height: 24,
                      backgroundColor: '#fff',
                      '&:before': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                      },
                      '&:hover, &.Mui-focusVisible, &.Mui-active': {
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
                <VolumeUpRounded
                  sx={{
                    color:
                      useTheme().palette.mode === 'dark'
                        ? 'rgba(255,255,255,0.4)'
                        : 'rgba(0,0,0,0.4)',
                  }}
                />
              </Stack>
            </Box>
          </Grid>

          <Hidden only={['xs']}>
            <Grid item sm={0.25}>
              <Divider orientation="vertical" sx={{ mr: '50%' }} />
            </Grid>
          </Hidden>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid item xs={11.5} sm={11.5}>
              <Divider sx={{ mt: '2%', mb: '1%' }} />
            </Grid>
          </Hidden>

          <Grid item xs={11.5} sm={5.75}>
            <Typography variant="h4" align="center">
              Add Music
            </Typography>
            <br />

            <Grid container item spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  id="toSearch"
                  label="Song to queue"
                  variant="filled"
                  disabled={!channels}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={4} md={3.5}>
                <FormControl
                  variant="filled"
                  disabled={!channels}
                  sx={{ width: '100%' }}
                >
                  <InputLabel>Channel</InputLabel>
                  <Select
                    id="selector"
                    value={channel}
                    label="Age"
                    onChange={handleChannelChange}
                  >
                    {channels &&
                      channels.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={8} md={8.5}>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => playMusic()}
                  variant="contained"
                  disabled={!channels}
                  sx={{ width: '100%', height: '100%' }}
                >
                  Play / Add to Queue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
    </motion.div>
  );
}

Guild.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Guild;
