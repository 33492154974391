import '../../App.css';
import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';
// import getDatabase,
// ref,
// onValue,
// get,
// update,
// push,
// off,
// 'firebase/database';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import GuildList from '../../components/GuildList';

function Dashboard() {
  const [guilds, setGuilds] = useState(null);

  useEffect(() => {
    function setGuildsData(data) {
      const newSet = [];
      Object.values(data).forEach((item) => {
        newSet.push({ id: item.id, name: item.name });
      });
      setGuilds(newSet);
    }

    // const guildSub = onValue(ref(getDatabase(), 'discord/guilds'), (snapshot) => {
    //   setGuildsData(snapshot.val());
    // });

    const guildsUnsub = onSnapshot(
      doc(getFirestore(), 'discord', 'guilds'),
      (d) => {
        setGuildsData(d.data());
      }
    );

    return () => {
      // off(guildSub);
      guildsUnsub();
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <Container maxWidth="xl">
        <br />

        <Typography variant="h2" align="center">
          Servers
        </Typography>
        <br />
        <GuildList guilds={guilds} sx={{ mb: '50px' }} />
        <br />
        {/* <Grid container align="center" justifyContent="center" spacing={2}>
            {guilds.map((guild) => (
              <Grid key={guild.id} item xs={6}>
                <Fade in>
                  <Button
                    component={Link}
                    to="/discord/guild"
                    style={{ width: '100%', height: '100%', fontSize: '300%' }}
                    state={{
                      guild: { id: guild.id, name: guild.name },
                    }}
                    variant="contained"
                    id={guild.id}
                  >
                    {guild.name}
                  </Button>
                </Fade>
              </Grid>
            ))}
          </Grid> */}
      </Container>
    </motion.div>
  );
}

export default Dashboard;
