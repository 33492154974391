import '../../App.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';

function Load() {
  const [linkText, setLink] = useState('');

  const handleLinkTextChange = (event) => {
    setLink(event.target.value);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <Container maxWidth="xl">
        <br />
        <Typography variant="h2" align="center">
          Download
        </Typography>
        <br />
        <br />

        <TextField
          id="toDownload"
          onChange={handleLinkTextChange}
          label="Link to download"
          variant="filled"
          style={{ minWidth: '100%' }}
        />
        <br />
        <br />
        <Button
          component={Link}
          to="/download/file"
          style={{ width: '100%', height: '100%', fontSize: '150%' }}
          state={{ linkToLoad: linkText }}
          variant="contained"
        >
          Load
        </Button>
      </Container>
    </motion.div>
  );
}

export default Load;
