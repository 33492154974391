import '../../App.css';
import React, { useEffect, useState } from 'react';
import { Container, Button, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
// import getDatabase,
// ref,
// onValue,
// get,
// update,
// push,
// off,
// 'firebase/database';
import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from 'firebase/firestore';
import ControlModule from '../../components/ControlModule';

function Dashboard() {
  const [loaded, setLoaded] = useState(false);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    const controlUnsub = onSnapshot(
      doc(getFirestore(), 'control', 'running'),
      (d) => {
        setLoaded(true);
        if (Object.keys(d.data()).includes('discord')) {
          setRunning(true);
        } else {
          setRunning(false);
        }
      }
    );

    // const guildSub = onValue(ref(getDatabase(), 'discord/guilds'), (snapshot) => {
    //   setGuildsData(snapshot.val());
    // });

    return () => {
      // off(guildSub);
      controlUnsub();
    };
  }, []);

  function updateControl(type, toAdd) {
    // const newKey = push(ref(getDatabase(), `control/${type}`)).key;
    const updates = {};
    // updates[`control/${type}/${newKey}`] = toAdd;
    // update(ref(getDatabase()), updates);
    updates[type] = arrayUnion(toAdd);
    updateDoc(doc(getFirestore(), 'control', type), toAdd);
  }

  // const startData = {
  //   name: 'discord',
  //   args: ['pipenv', 'run', 'python', 'main.py'],
  //   dir: 'Bots/Discord',
  // };

  const startData = {
    discord: {
      args: ['pipenv', 'run', 'python3', 'main.py'],
      dir: './Discord',
    },
  };

  function start() {
    updateControl('to_start', startData);
  }

  function stop() {
    updateControl('to_stop', { discord: null });
  }

  function restart() {
    updateControl('to_restart', startData);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <Container maxWidth="xl">
        <br />
        <Typography variant="h2" align="center">
          Discord Bot
        </Typography>
        <br />
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Button
              style={{ width: '100%' }}
              onClick={() => start()}
              variant="contained"
              disabled={loaded ? running : true}
            >
              Start
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              style={{ width: '100%' }}
              onClick={() => stop()}
              variant="contained"
              disabled={loaded ? !running : true}
            >
              Stop
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              style={{ width: '100%' }}
              onClick={() => restart()}
              variant="contained"
              disabled={loaded ? !running : true}
            >
              Restart
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        {/* <ControlModule
          to={{
            name: 'discord',
            args: ['pipenv', 'run', 'python3', 'main.py'],
            dir: './Discord',
          }}
        /> */}
        <br />
        <br />
        <ControlModule
          title="Video Download (Web)"
          to={{
            name: 'video_download_web',
            args: ['node', 'app.js'],
            dir: './DownloadServer/Web',
          }}
        />
        <br />
        <br />
        <br />
        <br />
        <ControlModule
          title="Video Download (Server)"
          to={{
            name: 'video_download_server',
            args: ['pipenv', 'run', 'python3', 'convert.py'],
            dir: './DownloadServer/Server',
          }}
        />
      </Container>
    </motion.div>
  );
}

// Dashboard.propTypes = {
//   controlWebsocket: PropTypes.instanceOf(WebSocket).isRequired,
// };

export default Dashboard;
